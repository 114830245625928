import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material';
import CloseIcon from '../../../images/icons/close-icon.svg';

interface RemoveButtonProps extends IconButtonProps {
  iconProps?: SvgIconProps;
}

const RemoveButton = ({ iconProps, sx, ...props }: RemoveButtonProps) => {
  return (
    <IconButton
      disableRipple
      {...props}
      sx={{
        p: 0,
        fill: '#0B14211A',
        '&:hover': {
          backgroundColor: 'transparent',
          fill: '#ABAFBF',
        },
        '&:focus': {
          outline: '2px solid #0B1421',
          outlineOffset: '2px',
        },
        ...sx,
      }}
    >
      <img
        alt="close"
        src={CloseIcon}
        style={{
          fontSize: '10px',
          fontWeight: '1000px',
        }}
      />
    </IconButton>
  );
};

export default RemoveButton;
