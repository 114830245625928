import React, { useEffect, useState } from 'react';
import '../styles/ConfirmationPageCard.scss';
import '../styles/Global.scss';
import Button from '@mui/material/Button';
import appConstant from '../helpers/appConstant';
import { useSegmentUtils } from '../helpers/useSegmentUtils';
import useSegment from '../hooks/useSegment';
import successIcon from '../images/icons/success-large.svg';
import checkIcon from '../images/icons/check-icon.svg';
import Container from '@mui/material/Container';
import { getCookie } from './helpers/cookies';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

function ConfirmationPage() {
  const [isUpdateMortgagee, setIsUpdateMortgagee] = useState(false);
  const { track } = useSegment();
  const navigate = useNavigate();
  const { trackMessage } = useSegmentUtils();
  useEffect(() => {
    const contactInformation = sessionStorage.getItem('ContactInfo');
    if (!getCookie('token')) {
      navigate('/');
    }
    setIsUpdateMortgagee(
      JSON.parse(contactInformation || '{}').isUpdateMortgagee || false
    );
    trackMessage('confirmation-page', appConstant.REQUEST_SUBMITTED);
  }, []);

  const handleClick = () => {
    track('Additional Submission Requested', {
      event_type: 'Link Accessed',
    });
    sessionStorage.clear();
  };
  return (
    <div className="DocumentDetailsCard">
      <div className="PolicyDetailsCard-title">Request submitted</div>
      <div className="Success-alert">
        <img src={checkIcon} className="alert-icon" alt="" />
        <b>{appConstant.REQUEST_SUBMITTED}</b>
      </div>
      <div className="PolicyDetailsCard-main">
        <Container
          disableGutters
          sx={{ bgcolor: '#ffffff', borderRadius: ' 0 0 8px 8px' }}
        >
          <Stack justifyContent="center" sx={{ p: 5 }}>
            <img src={successIcon} className="Success-image" alt="" />
            <Typography
              sx={{
                fontSize: { xs: 18 },
                color: '#373B49',
                textAlign: 'center',
              }}
            >
              {isUpdateMortgagee
                ? 'We are working on your request and will send you the updated documents through your preferred method within two business days.'
                : 'We are working on your request and will send you the documents through your preferred method soon.'}
            </Typography>
            <Stack justifyContent="flex-end" flexDirection="row" sx={{ mt: 5 }}>
              <Button variant="contained" href="/" onClick={handleClick}>
                Submit another request
              </Button>
            </Stack>
          </Stack>
        </Container>
      </div>
    </div>
  );
}
export default ConfirmationPage;
