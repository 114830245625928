import React from 'react';
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Header from './components/Header';
import Footer from './components/Footer';
import DocumentDetailsCard from './components/DocumentDetailsCard';

function DocumentDetails() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container disableGutters maxWidth={false}>
        <Header />
        <Container disableGutters maxWidth={false} className="body">
          <DocumentDetailsCard />
        </Container>
        <Footer />
      </Container>
    </LocalizationProvider>
  );
}
export default DocumentDetails;
