import React from 'react';
import { Stack, Link, Divider, Typography } from '@mui/material';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <Stack flexDirection="column" alignItems="center" sx={{ p: 3 }}>
          <Stack
            justifyContent="center"
            alignItems={{ xs: 'left', md: 'center' }}
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              flexWrap: 'wrap',
              mb: { xs: 3, md: 2 },
            }}
          >
            <Link
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/misc/terms-of-use.html"
              rel="noopener"
              target="_blank"
              underline="none"
              sx={{
                color: 'primary',
                fontWeight: 700,
                fontSize: { xs: 14, md: 18 },
                fontFamily: 'Cabin',
                whiteSpace: 'nowrap',
              }}
            >
              {'Terms of Use'}
            </Link>
            <Divider
              orientation="vertical"
              sx={{
                height: '1rem',
                mx: { xs: 0, md: 2 },
              }}
            />
            <Link
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/misc/privacy-notice.html"
              rel="noopener"
              target="_blank"
              underline="none"
              sx={{
                color: 'primary',
                fontWeight: 700,
                fontSize: { xs: 14, md: 18 },
                fontFamily: 'Cabin',
                whiteSpace: 'nowrap',
              }}
            >
              {'Privacy Policy'}
            </Link>
          </Stack>
          <Stack alignItems="center">
            <Typography
              sx={{
                //color: colors.csaaMid,
                fontSize: { xs: 10, md: 12 },
                lineHeight: { xs: '16px' },
                fontFamily: 'Cabin',
              }}
            >
              Insurance provided by CSAA Insurance Group, a AAA Insurer
            </Typography>
            <Typography
              sx={{
                //color: colors.csaaMid,
                fontSize: { xs: 10, md: 12 },
                lineHeight: { xs: '16px' },
                fontFamily: 'Cabin',
              }}
            >
              © {currentYear} CSAA Insurance Group
            </Typography>
          </Stack>
        </Stack>
      </footer>
    </>
  );
}
