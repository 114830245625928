export default {
  UNABLE_TO_PROCESS: 'We’re unable to process your request.',
  TRY_AGAIN: 'Please try again later.',
  REQUEST_SUBMITTED: 'Your request has been submitted.',
  DOCUMENTS_WILL_BE_SENT:
    'We are processing your request. The documents will be sent to the method selected.',
  MORTGAGEE_CHANGES_PROCESSED: 'Your mortgagee changes have been processed.',
  VIEW_REQUEST_DOCS: 'View or request your updated documents below.',
  UNABLE_UPDATE_MORTGAGEE: 'We’re unable to update this mortgagee.',
  CHECK_AND_TRY_AGAIN: 'Please check your details and try again.',
  NO_MORTGAGEE_FOUND: 'No mortgagee information found.',
};
