import React from 'react';
import InsuredContextProvider from './components/contexts/InsuredContext';
import LoadingContextProvider from './components/contexts/LoadingContext';
import IdleTimer from './components/IdleTimer';
import { Outlet } from 'react-router-dom';
import RealTimeResponseContextProvider from './components/contexts/RealTimeResponseContext';
import { CssVarsProvider } from '@aaa-ncnu-ie/lumina-theme';
import '@aaa-ncnu-ie/lumina-fonts/cabin/index.css';
import '@aaa-ncnu-ie/lumina-fonts/rt-raleway/index.css';

const Root = () => {
  return (
    <LoadingContextProvider>
      <InsuredContextProvider>
        <RealTimeResponseContextProvider>
          <CssVarsProvider>
            <React.StrictMode>
              <Outlet />
            </React.StrictMode>
            <IdleTimer />
          </CssVarsProvider>
        </RealTimeResponseContextProvider>
      </InsuredContextProvider>
    </LoadingContextProvider>
  );
};

export default Root;
