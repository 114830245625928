import { useCallback } from 'react';
import { useLocation, useBlocker, Location } from 'react-router-dom';

interface BlockerArgs {
  nextLocation: Location;
}

const usePathnameBlocker = (
  paths: string[],
  handleExit: (val: boolean) => void,
  allowNavigation?: boolean
) => {
  const { pathname } = useLocation();

  const blockerFunction = useCallback(
    ({ nextLocation }: BlockerArgs) => {
      if (
        !allowNavigation &&
        paths.some((path) => nextLocation.pathname === path)
      ) {
        handleExit(true);
        return true;
      }
      return false;
    },
    [pathname, allowNavigation]
  );

  const blocker = useBlocker(blockerFunction);

  const proceedWithNavigation = () => {
    if (blocker && blocker.state === 'blocked' && blocker.proceed) {
      blocker.proceed();
      handleExit(false);
    }
  };

  return proceedWithNavigation;
};

export default usePathnameBlocker;
