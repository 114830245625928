import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Pathname } from 'react-router-dom';

interface OutlinedButtonProps extends LoadingButtonProps {
  width: number | string;
}

export const OutlinedButton = (props: OutlinedButtonProps) => (
  <Button
    variant="outlined"
    color="inherit"
    {...props}
    sx={{
      color: '#0B1421',
      borderColor: '#0B1421',
      whiteSpace: 'nowrap',
      borderRadius: 1,
      width: { mobile: 1, tablet: props.fullWidth ? 1 : props.width },
      '&:hover': {
        borderRadius: 1,
        borderWidth: 2,
      },
      ...props.sx,
    }}
  />
);

const Button = <C extends React.ElementType>(
  props: LoadingButtonProps<C, { component?: C; to?: Pathname }>
) => {
  const disabledProps = props.disabled
    ? { to: undefined, component: undefined }
    : {}; // This solves the AQA problems when using link buttons.

  return (
    <LoadingButton
      variant="contained"
      disableElevation
      disableRipple
      {...props}
      {...disabledProps}
      sx={{
        textTransform: 'none',
        height: 42,
        width: { mobile: 1, tablet: props.fullWidth ? 1 : 'auto' },
        fontSize: { mobile: 16, tablet: 18 },
        py: { mobile: 1 },
        px: [null, 4, 4],
        fontWeight: props.disabled ? 400 : 700,
        alignSelf: 'flex-end',
        whiteSpace: 'nowrap',
        ...props.sx,
      }}
    >
      {props.children}
    </LoadingButton>
  );
};

export default Button;
