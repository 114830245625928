import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import {
  Container,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '../../images/icons/close-icon.svg';

type ModalProps = {
  title: string | ReactElement;
  children: React.ReactNode;
  open: boolean;
  onClose: any;
  modalName: string;
  onGoBack?: () => void;
};

const Modal = ({
  title,
  children,
  open,
  onClose,
  modalName,
  ...rest
}: ModalProps & Omit<DialogProps, 'title'>) => {
  useEffect(() => {
    // NOTE: This allows to reset focus so that keyboard navigation
    // can continue from the top of the screen (instead of from the bottom like
    // it is currently)
    if (!open) {
      const container = document.getElementById('landing-page-container');
      container && container.focus();
    }
  }, [open]);

  const { className } = rest;
  return (
    <Dialog
      aria-labelledby={'csaa-modal-title'}
      aria-describedby={'csaa-modal-title'}
      {...rest}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: (theme) => ({
          maxWidth: 540,
          px: 4,
          pt: 3,
          pb: 5,
          borderRadius: 4,
          bgcolor: theme.vars.sys.color.background,
        }),
      }}
    >
      <Box className="modal-content">
        <Container disableGutters maxWidth={false} className="modal-header">
          <Stack flexDirection="row" justifyContent="flex-end">
            <IconButton
              disableTouchRipple
              aria-label="close"
              onClick={onClose}
              sx={{
                '&:hover': {
                  bgcolor: 'inherit',
                  outlineOffset: -8,
                },
                '&.Mui-focusVisible': {
                  outlineOffset: -8,
                  bgcolor: 'inherit',
                },
              }}
            >
              <img
                alt="close"
                src={CloseIcon}
                style={{
                  fontSize: '10px',
                  fontWeight: '1000px',
                }}
              />
            </IconButton>
          </Stack>
          <Stack flexDirection="row" justifyContent="center" sx={{ my: 3 }}>
            <Typography
              variant="h4"
              id="csaa-modal-title"
              sx={{ fontSize: 24, fontWeight: 700 }}
            >
              {title}
            </Typography>
          </Stack>
        </Container>
        <div>{children}</div>
      </Box>
    </Dialog>
  );
};

export default Modal;
