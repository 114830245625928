import * as yup from 'yup';

const documentDetailsCardSchema = yup.object({
  name: yup
    .string()
    .required('Your name is required')
    .matches(/^[a-zA-Z\s'-]+$/, 'Name must be alphabetic'),
  lenderName: yup
    .string()
    .required('Full Lender Name is required')
    .matches(
      /^[a-zA-Z0-9\s-()&.',?]+$/,
      'Full Lender Name must be alphanumeric'
    ),
  phone: yup
    .string()
    .required('Phone Number is required')
    .matches(/^\d{10}$/, 'Phone Number must have 10 characters'),
  showEmail: yup.boolean().required(),
  email: yup.string().when('showEmail', {
    is: true,
    then: (schema: any) =>
      schema.required('Email is required').email('Invalid email'),
  }),
  showFax: yup.boolean().required(),
  fax: yup.string().when('showFax', {
    is: true,
    then: (schema: any) =>
      schema
        .required('Fax Number is required')
        .matches(/^\d{10}$/, 'Fax Number must have 10 characters'),
  }),
});

export default documentDetailsCardSchema;
