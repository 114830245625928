import '../styles/Global.scss';
import React from 'react';
import maintenanceImg from '../images/maintenance.svg';
import BasicLayout from './layouts/BasicLayout';
import { Stack, Typography } from '@mui/material';

const MaintenanceCard = () => {
  return (
    <BasicLayout>
      <Stack flexDirection="row">
        <Stack sx={{ width: 0.5 }}>
          <img src={maintenanceImg} alt="" />
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({
            bgcolor: theme.vars.sys.color.background,
            mt: -30,
            width: 0.5,
          })}
        >
          <Stack sx={{ mt: 30, width: 528 }}>
            <Typography
              sx={{
                fontFamily: 'RT Raleway',
                fontSize: { xs: 44 },
                lineHeight: { xs: '62px' },
                fontWeight: 700,
                color: '#0B1421',
              }}
            >
              Sorry, we&apos;re down for maintenance.
            </Typography>
            <Typography sx={{ color: '#4D5160' }}>
              We should be up and running shortly. Thanks for your patience.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </BasicLayout>
  );
};

export default MaintenanceCard;
