import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Header from './components/Header';
import Footer from './components/Footer';
import PolicyDetailsCard from './components/PolicyDetailsCard';
import RealTimePolicyDetailsCard from './components/RealTimePolicyDetailsCard';
import ExitConfirmationModal from './components/modal/ExitConfirmationModal';
import usePathnameBlocker from './hooks/usePathnameBlocker';

const isRealTimeEnabled = process.env.REACT_APP_ENABLE_MORTGAGEE === 'true';

function PolicyDetails() {
  const [showExitDialog, setShowExitDialog] = useState(false);
  const navigate = useNavigate();
  const navigationBlocker = usePathnameBlocker(['/'], setShowExitDialog);

  const handleContinue = async () => {
    navigationBlocker();
    navigate('/', { replace: true });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth={false} disableGutters>
        <Header />
        <Container maxWidth={false} disableGutters className="body">
          {!isRealTimeEnabled ? (
            <PolicyDetailsCard />
          ) : (
            <RealTimePolicyDetailsCard />
          )}
          <ExitConfirmationModal
            showRemoveIcon
            open={showExitDialog}
            onClose={() => setShowExitDialog(false)}
            handleContinue={handleContinue}
            title="Are you sure you want to leave this page?"
            bodyText={['By selecting “Yes”, you will need to start over.']}
            leaveText="Yes, leave"
            stayText="No, stay"
          />
        </Container>
        <Footer />
      </Container>
    </LocalizationProvider>
  );
}

export default PolicyDetails;
