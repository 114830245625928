import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import '../styles/./PolicyDetailsCard.scss';
import '../styles/DocumentDetailsCard.scss';
import '../styles/Global.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DocumentsRequest,
  DocumentsRequestNameEmail,
} from '../api/schema/apiDocuments';
import documentDetailsCardSchema from '../yup-schemas/DocumentDetailsCard';
import { useNavigate } from 'react-router-dom';
import { getCookie } from './helpers/cookies';
import policyDetails from '../helpers/policyDetails';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Stack,
} from '@mui/material';
import { PatternFormat } from 'react-number-format';
import useSegment from '../hooks/useSegment';
import { InsuredContext } from './contexts/InsuredContext';
import BasicLayout from './layouts/BasicLayout';

function RealTimeDocumentDetails() {
  const navigate = useNavigate();
  const [insuredContext] = useContext(InsuredContext);
  const [name, setName] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [phone, setPhone] = useState('');
  const [, setEOI] = useState(false);
  const [, setReplacement] = useState(false);
  const [, setBill] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([] as Array<string>);
  const { track } = useSegment();

  const [emailButton, setEmail] = useState(false);
  const [faxButton, setFax] = useState(false);
  const [email, setEmailInput] = useState('');
  const [fax, setFaxInput] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const showEmail = selectedOption === 'email';
  const showFax = selectedOption === 'fax';

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const documents: DocumentsRequestNameEmail = JSON.parse(
      sessionStorage.getItem('Request Documents') || '{}'
    );
    if (!getCookie('token')) {
      navigate('/');
    }
    setName('');
    setLenderName(documents.lenderName || '');
    setEmailInput(documents?.email || '');
    setFaxInput('');
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(documentDetailsCardSchema),
    values: {
      name,
      lenderName,
      phone,
      showEmail,
      email,
      showFax,
      fax,
    },
  });

  const handleDocumentTypeChange = (docType: string) => {
    if (documentTypes.indexOf(docType) < 0) {
      setDocumentTypes(documentTypes.concat(docType));
    } else {
      setDocumentTypes(documentTypes.filter((doc) => docType !== doc));
    }
  };

  const segmentTrackDocumentCheckboxSelected = (
    checked: boolean,
    document: string
  ) => {
    if (checked) {
      track('Document Request Changed', {
        document,
        action: checked ? 'selected' : 'deselected',
        event_type: 'Option Selected',
      });
    }
  };

  const segmentTrackDeliveryCheckbox = (
    checked: boolean,
    deliveryMethod: string
  ) => {
    track('Delivery Method Changed', {
      delivery_method: deliveryMethod,
      action: checked ? 'selected' : 'deselected',
      event_type: 'Option Selected',
    });
  };

  const handleEOI = (checked: boolean) => {
    setEOI(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Evidence of Insurance');
    handleDocumentTypeChange('EVIDENCE_OF_INSURANCE');
  };
  const handleReplaceCost = (checked: boolean) => {
    setReplacement(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Replacement Cost Estimator');
    handleDocumentTypeChange('REPLACEMENT_COST');
  };
  const handleBill = (checked: boolean) => {
    setBill(checked);
    segmentTrackDocumentCheckboxSelected(checked, 'Bill');
    handleDocumentTypeChange('INVOICE_PROPERTY');
  };
  const handleEmailOrFax = (value: any) => {
    const emailChecked = value === 'email';
    const faxChecked = value === 'fax';
    setEmail(emailChecked);
    segmentTrackDeliveryCheckbox(emailChecked, 'email');
    setFax(faxChecked);
    segmentTrackDeliveryCheckbox(faxChecked, 'fax');
  };

  const handleClick = () => {
    const docsRequest: DocumentsRequest = policyDetails.buildRealTimeDocRequest(
      name.trim(),
      insuredContext,
      emailButton,
      faxButton,
      documentTypes
    );
    sessionStorage.setItem('Documents', JSON.stringify(docsRequest));
  };

  return (
    <BasicLayout>
      <Stack
        sx={(theme) => ({
          bgcolor: theme.vars.sys.color.background,
          px: 7,
          py: 5,
          pb: 12,
        })}
      >
        <div className="DocumentDetailsCard">
          <div className="PolicyDetailsCard-title">Request documents</div>
          <div className="PolicyDetailsCard-main">
            <Container
              sx={{
                px: 3,
                py: 5,
                bgcolor: '#ffffff',
                borderRadius: '0 0 8px 8px',
              }}
            >
              <Box className="PolicyDetailsCard-box-details">
                <Stack>
                  <div className="flexForm">
                    <div className="DocumentDetailsCard-title-middle">
                      Select documents to send via email or fax
                    </div>

                    <FormGroup>
                      <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                          alignItems: 'center',
                          border: '1px solid #00000066',
                          borderRadius: '4px',
                          marginBottom: 3,
                          width: 816,
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            height: 57,
                            paddingLeft: 3,
                            width: 1,
                          }}
                          control={
                            <Checkbox
                              onClick={() => handleEOI(true)}
                              sx={{
                                color: '#0B1421',
                                '&.Mui-checked': {
                                  color: '#0B1421',
                                },
                              }}
                            />
                          }
                          label="  Evidence of insurance"
                        />
                      </Stack>
                      <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                          alignItems: 'center',
                          border: '1px solid #00000066',
                          borderRadius: '4px',
                          marginBottom: 3,
                          width: 816,
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            height: 57,
                            paddingLeft: 3,
                            width: 1,
                          }}
                          control={
                            <Checkbox
                              onClick={() => handleReplaceCost(true)}
                              sx={{
                                color: '#0B1421',
                                '&.Mui-checked': {
                                  color: '#0B1421',
                                },
                              }}
                            />
                          }
                          label="Replacement Cost Estimator"
                        />
                      </Stack>
                      <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                          alignItems: 'center',
                          border: '1px solid #00000066',
                          borderRadius: '4px',
                          marginBottom: 3,
                          width: 816,
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            height: 57,
                            paddingLeft: 3,
                            width: 1,
                          }}
                          control={
                            <Checkbox
                              onClick={() => handleBill(true)}
                              sx={{
                                color: '#0B1421',
                                '&.Mui-checked': {
                                  color: '#0B1421',
                                },
                              }}
                            />
                          }
                          label="Bill"
                        />
                      </Stack>
                    </FormGroup>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          color: 'black',
                          font: 'Cabin',
                          weight: '400',
                          size: '18px',
                          lineHeight: '24px',
                          paragraph: '12px',
                          letter: '0.4px',
                          marginBottom: '20px',
                        }}
                      >
                        Delivery method
                      </FormLabel>
                      <RadioGroup
                        name="use-radio-group"
                        value={selectedOption}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="email"
                          label="Email"
                          control={
                            <Radio onClick={() => handleEmailOrFax('email')} />
                          }
                          sx={{ marginBottom: '20px' }}
                        />
                        {selectedOption === 'email' && (
                          <Stack spacing={3} sx={{ mb: 3 }}>
                            <TextField
                              {...register('name')}
                              error={!!errors.name}
                              label="Your name"
                              fullWidth
                              inputProps={{ maxLength: 80 }}
                              variant="outlined"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              helperText={`${errors.name?.message || ''}`}
                              required
                            />
                            <TextField
                              {...register('lenderName')}
                              error={!!errors.lenderName}
                              label="Full lender name"
                              fullWidth
                              inputProps={{ maxLength: 120 }}
                              variant="outlined"
                              value={lenderName}
                              onChange={(e) => setLenderName(e.target.value)}
                              helperText={`${errors.lenderName?.message || ''}`}
                              required
                            />
                            <PatternFormat
                              customInput={TextField}
                              format={'###-###-####'}
                              mask="_"
                              onValueChange={({ value }) => {
                                setValue('phone', value);
                                setPhone(value);
                              }}
                              onBlur={() => {
                                trigger('phone');
                              }}
                              error={!!errors.phone}
                              label="Phone number"
                              fullWidth
                              InputProps={{
                                classes: {
                                  input: 'csaa-qm-encrypt',
                                },
                              }}
                              variant="outlined"
                              value={phone}
                              helperText={`${errors.phone?.message || ''}`}
                              required
                            />
                            {showEmail && (
                              <TextField
                                {...register('email')}
                                error={!!errors.email}
                                label="Email"
                                fullWidth
                                variant="outlined"
                                value={email}
                                inputProps={{ readOnly: true, maxLength: 256 }}
                                onChange={(e) => setEmailInput(e.target.value)}
                                helperText={`${
                                  errors.email?.message ||
                                  "Enter the recipient's email address."
                                }`}
                                required
                              />
                            )}
                          </Stack>
                        )}
                        <FormControlLabel
                          value="fax"
                          label="Fax"
                          control={
                            <Radio onClick={() => handleEmailOrFax('fax')} />
                          }
                        />
                        {selectedOption === 'fax' && (
                          <Stack sx={{ mt: 2.5 }} spacing={3}>
                            <TextField
                              {...register('name')}
                              error={!!errors.name}
                              label="Your name"
                              fullWidth
                              variant="outlined"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              inputProps={{ maxLength: 80 }}
                              helperText={`${errors.name?.message || ''}`}
                              required
                            />
                            <TextField
                              {...register('lenderName')}
                              error={!!errors.lenderName}
                              label="Full lender name"
                              fullWidth
                              variant="outlined"
                              value={lenderName}
                              inputProps={{ maxLength: 120 }}
                              onChange={(e) => setLenderName(e.target.value)}
                              helperText={`${errors.lenderName?.message || ''}`}
                              required
                            />
                            <PatternFormat
                              customInput={TextField}
                              format={'###-###-####'}
                              mask="_"
                              onValueChange={({ value }) => {
                                setValue('phone', value);
                                setPhone(value);
                              }}
                              onBlur={() => {
                                trigger('phone');
                              }}
                              error={!!errors.phone}
                              label="Phone number"
                              fullWidth
                              InputProps={{
                                classes: {
                                  input: 'csaa-qm-encrypt',
                                },
                              }}
                              variant="outlined"
                              value={phone}
                              helperText={`${errors.phone?.message || ''}`}
                              required
                            />
                            {showFax && (
                              <PatternFormat
                                customInput={TextField}
                                format={'###-###-####'}
                                mask="_"
                                InputProps={{
                                  classes: {
                                    input: 'csaa-qm-encrypt',
                                  },
                                }}
                                onValueChange={({ value }) => {
                                  setValue('fax', value);
                                  setFaxInput(value);
                                }}
                                onBlur={() => {
                                  trigger('fax');
                                }}
                                error={!!errors.fax}
                                label="Fax Number"
                                variant="outlined"
                                fullWidth
                                value={fax}
                                helperText={`${errors.fax?.message || ''}`}
                                required
                              />
                            )}
                          </Stack>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Stack>
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  sx={{ mt: 5, width: 1 }}
                >
                  <Button
                    sx={{
                      color: '#0B1421',
                      borderColor: '#0B1421',
                      '&:hover': {
                        borderColor: '#0B1421',
                      },
                    }}
                    variant="outlined"
                    onClick={() => {
                      navigate('/details');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(() => {
                      sessionStorage.setItem(
                        'ContactInfo',
                        JSON.stringify({
                          name: name.trim(),
                          lenderName: lenderName.trim(),
                          phone,
                          email: email.trim(),
                          fax,
                        })
                      );
                      handleClick();
                      navigate('/review');
                    })}
                    disabled={
                      !documentDetailsCardSchema.isValidSync(getValues())
                    }
                    sx={{ ml: 2 }}
                  >
                    Continue
                  </Button>
                </Stack>
              </Box>
            </Container>
          </div>
        </div>
      </Stack>
    </BasicLayout>
  );
}
export default RealTimeDocumentDetails;
