import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { Stack } from '@mui/material';

const BasicLayout = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack sx={{ px: 0 }}>
        <Header />
        {children}
        <Footer />
      </Stack>
    </LocalizationProvider>
  );
};

export default BasicLayout;
