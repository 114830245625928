import { getYearFromStringDateDDmmYYYY } from '../components/helpers/utils';
import useSegment from '../hooks/useSegment';

export const useSegmentUtils = () => {
  const { identify, track } = useSegment();
  const trackValidationErrorMessage = (
    errors: any,
    validationErrors: any,
    setValidationErrors: any
  ) => {
    if (Object.keys(errors).length >= Object.keys(validationErrors).length) {
      for (const key in errors) {
        const errorMessage = errors[key]?.message;
        const fieldValue = errors[key]?.ref?.value;
        const fieldName = errors[key]?.ref?.name;
        const validationMessage = validationErrors[key]?.message;
        if (
          errorMessage &&
          (!validationMessage || errorMessage !== validationMessage)
        ) {
          track('Input Validation Error', {
            reason: errorMessage,
            input: fieldValue,
            field_name: fieldName,
            event_type: 'Automated System Process',
          });
        }
      }
    }
    setValidationErrors({ ...errors });
  };

  const identifySegmentUser = (insuredContext: any) => {
    identify(insuredContext.lenderEmail, {
      id: insuredContext.lenderEmail,
      lender_name:
        insuredContext.lenderName === 'Other - Please Specify'
          ? insuredContext.lenderOther
          : insuredContext.lenderName,
    });
  };

  const trackSegmentUser = (insuredContext: any) => {
    track('Policy Information Requested', {
      insured_last_name: insuredContext.insuredLastName,
      zip_code: insuredContext.propertyZipCode,
      insured_dob: getYearFromStringDateDDmmYYYY(
        insuredContext?.insuredDateOfBirth
      ),
      event_type: 'User Information Entered',
    });
  };

  const trackInputValue = (event: any, register: any) => {
    const { onBlur: originalOnBlur } = register(event.target.name);
    originalOnBlur(event);

    track('Field Information Entered', {
      field_name: event.target.name,
      input:
        typeof event.target.value !== 'number'
          ? event.target.value
          : event.target.innerText,
      event_type: 'User Information Entered',
    });
  };

  const trackMessage = (location: string, message: string) => {
    track('User Messaged', {
      message_type: 'Warning',
      location,
      message,
    });
  };

  return {
    trackValidationErrorMessage,
    trackSegmentUser,
    identifySegmentUser,
    trackInputValue,
    trackMessage,
  };
};
