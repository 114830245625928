import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import lenderImg from '../images/lender-landing-image.svg';
import LocationIcon from '../images/icons/location-icon.svg';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import { InsuredContext } from './contexts/InsuredContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import policyLookupSchema from '../yup-schemas/PolicyLookupCard';
import dayjs from 'dayjs';
import ReCAPTCHA from 'react-google-recaptcha';
import useRequest from '../api/makeRequest';
import { useActionCreators } from '../api/actions';
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import lenderNames from './data/lenderNames.json';
import { clearSessionAndCookies } from './helpers/cookies';
import { useSegmentUtils } from '../helpers/useSegmentUtils';
import appConstant from '../helpers/appConstant';

function PolicyLookup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(policyLookupSchema),
  });
  const {
    trackInputValue,
    identifySegmentUser,
    trackValidationErrorMessage,
    trackSegmentUser,
    trackMessage,
  } = useSegmentUtils();
  const [insuredDateOfBirth, setInsuredDateOfBirth] = React.useState(null);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [otherText, setOtherText] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [insuredContext, setInsuredContext] = useContext(InsuredContext);
  const [recaptchaToken, setRecaptchaToken] = React.useState('');
  const [recaptchaMessage, setRecaptchaMessage] = React.useState('');
  const navigate = useNavigate();
  const isRecaptchaEnabled =
    process.env.REACT_APP_GOOGLE_RECAPTCHA_ENABLED === 'true';

  const handleInput = (event: any) => {
    setInsuredContext({
      ...insuredContext,
      ...{
        [event.target.name]:
          typeof event.target.value !== 'number'
            ? event.target.value
            : event.target.innerText,
      },
    });
  };

  useEffect(() => {
    trackValidationErrorMessage(errors, validationErrors, setValidationErrors);
  }, [
    errors?.policyNumber?.message,
    errors?.insuredDateOfBirth?.message,
    errors?.insuredLastName?.message,
    errors?.propertyZipCode?.message,
    errors?.lenderName?.message,
    errors?.lenderOther?.message,
    errors?.lenderEmail?.message,
  ]);

  useEffect(() => {
    if (insuredContext.errorRetrievingPolicy) {
      trackMessage('policy-lookup', appConstant.UNABLE_TO_PROCESS);
    }
  }, [insuredContext?.errorRetrievingPolicy]);

  useEffect(() => {
    clearSessionAndCookies();
    setInsuredContext({
      incorrectPolicyInformation: insuredContext.incorrectPolicyInformation,
      errorRetrievingPolicy: insuredContext.errorRetrievingPolicy,
    });
    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  const isOther = (value: string | null) =>
    ['Other - Please Specify', 'Lender name not found, add now?'].includes(
      value || ''
    );

  const {
    actionCreators: { validateRecaptcha },
  } = useActionCreators();
  const makeRequest = useRequest();

  return (
    <>
      <Stack justifyContent="space-around" direction="row" flex={1}>
        <Stack
          justifyContent="center"
          direction="column"
          alignItems="center"
          flex={1}
        >
          <img src={lenderImg} alt="" />
        </Stack>
        <Stack
          sx={(theme) => ({
            bgcolor: theme.vars.sys.color.background,
            flex: 1,
            p: { xs: 3, md: 15, lg: 0 },
            mt: { lg: -30 },
          })}
        >
          <Stack
            justifyContent="center"
            sx={{
              p: '12px 88px 40px',
              mt: { lg: 30 },
            }}
          >
            <Container disableGutters>
              <Collapse
                in={insuredContext.incorrectPolicyInformation}
                unmountOnExit
              >
                <Alert
                  severity="error"
                  className="PolicyLookupCard-error-message"
                  onClose={() => {
                    setInsuredContext((state) => ({
                      ...state,
                      incorrectPolicyInformation: false,
                    }));
                  }}
                  sx={{ fontFamily: 'Cabin', py: { xs: 1 } }}
                >
                  <AlertTitle
                    sx={{ fontWeight: 700, fontSize: 18, color: '#0b1421' }}
                  >
                    We’re unable to locate this policy.
                  </AlertTitle>
                  <Typography
                    sx={{
                      fontSize: { xs: 18 },
                      color: '#4d5160',
                    }}
                  >
                    Please check your information and try again.
                  </Typography>
                </Alert>
              </Collapse>
            </Container>
            {insuredContext.errorRetrievingPolicy && (
              <Alert
                severity="error"
                className="PolicyLookupCard-error-message"
                sx={{ py: { xs: 1 } }}
              >
                <AlertTitle
                  sx={{ fontWeight: 700, fontSize: 18, color: '#0b1421' }}
                >
                  {appConstant.UNABLE_TO_PROCESS}
                </AlertTitle>
                <Typography
                  sx={{
                    fontSize: { xs: 18 },
                    color: '#4d5160',
                  }}
                >
                  {appConstant.TRY_AGAIN}
                </Typography>
              </Alert>
            )}
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: 33, md: 44 },
                lineHeight: { xs: '48px', lg: '56px' },
              }}
            >
              LenderHub, the AAA Insurance Self-Service Portal
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: 23, md: 24 },
                mt: { xs: 1, md: 1, lg: 2 },
                lineHeight: { xs: '32px' },
              }}
            >
              LenderHub is an easy way to review policy status, current
              mortgagee & lien holder information, request mortgagee changes,
              and evidence of insurance documents.
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Cabin',
                fontKerning: 'normal',
                fontSize: { xs: 14, md: 16 },
                my: 3,
              }}
            >
              Please enter the information below to locate a policy
            </Typography>
            <Stack spacing={2}>
              <TextField
                {...register('policyNumber')}
                error={!!errors.policyNumber}
                label="Policy Number"
                id="policy-number"
                name="policyNumber"
                helperText={`${
                  errors.policyNumber?.message || ''
                } ex. AZH3987654321`}
                fullWidth
                onChange={handleInput}
                onBlur={(e) => trackInputValue(e, register)}
                required
                inputProps={{
                  maxLength: 15,
                  style: { textTransform: 'uppercase' },
                }}
              />
              <TextField
                {...register('insuredLastName')}
                error={!!errors.insuredLastName}
                label="Insured Last Name"
                id="insured-last-name"
                onBlur={(e) => trackInputValue(e, register)}
                fullWidth
                name="insuredLastName"
                helperText={`${errors.insuredLastName?.message || ''}`}
                onChange={handleInput}
                required
              />
              <Stack direction="row" justifyContent="space-between">
                <TextField
                  {...register('propertyZipCode')}
                  error={!!errors.propertyZipCode}
                  label="Property Zip Code"
                  name="propertyZipCode"
                  onBlur={(e) => trackInputValue(e, register)}
                  helperText={`${errors.propertyZipCode?.message || ''}`}
                  onChange={handleInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={LocationIcon} alt="" />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 5 }}
                  required
                  sx={{ flexGrow: 1 }}
                />
                <DesktopDatePicker
                  label="Insured Date of Birth"
                  inputFormat="MM/DD/YYYY"
                  value={insuredDateOfBirth}
                  onChange={(value) => {
                    setInsuredDateOfBirth(value);
                    const formattedDate = dayjs(value).format('MM/DD/YYYY');
                    setValue('insuredDateOfBirth', formattedDate);
                    handleInput({
                      target: {
                        name: 'insuredDateOfBirth',
                        value: formattedDate,
                      },
                    });
                  }}
                  disableFuture
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register('insuredDateOfBirth')}
                      error={!!errors.insuredDateOfBirth}
                      InputProps={{
                        classes: {
                          input: 'csaa-qm-block',
                        },
                      }}
                      sx={{ flexGrow: 1, ml: 1 }}
                      name="insuredDateOfBirth"
                      onBlur={(e) => trackInputValue(e, register)}
                      helperText={`${errors.insuredDateOfBirth?.message || ''}`}
                      required
                    />
                  )}
                />
              </Stack>
              <Autocomplete
                id="lender-id"
                options={lenderNames}
                ListboxProps={{ className: 'drop-down-item' }}
                onBlur={() => {
                  trigger('lenderName');
                }}
                onChange={(event: any, newValue: string | null) => {
                  handleInput({
                    target: {
                      name: 'lenderName',
                      value: newValue,
                    },
                  });
                  setValue('lenderName', newValue || '');
                  if (isOther(newValue)) {
                    setOtherText(true);
                  } else {
                    setOtherText(false);
                  }
                }}
                filterOptions={(options) => {
                  const filtered = options.filter((option) => {
                    return option
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  });

                  if (filtered.length === 0) {
                    filtered.push('Lender name not found, add now?');
                  }

                  return filtered;
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.lenderName}
                    label="Lender Name"
                    fullWidth
                    name="PolicyLenderName"
                    onBlur={(e) => trackInputValue(e, register)}
                    className="no-shadow menu-select"
                    helperText="Select or begin typing the name of the lender you represent"
                    required
                  />
                )}
                isOptionEqualToValue={(option, inputValue) =>
                  [option, 'Lender name not found, add now?'].includes(
                    inputValue
                  )
                }
              />
              {otherText && (
                <TextField
                  {...register('lenderOther')}
                  error={!!errors.lenderOther}
                  label="Other Lender"
                  id="other-lender"
                  name="lenderOther"
                  onBlur={(e) => trackInputValue(e, register)}
                  helperText={`${errors.lenderOther?.message || ''}`}
                  onChange={handleInput}
                  fullWidth
                  required
                />
              )}
              <TextField
                {...register('lenderEmail')}
                error={!!errors.lenderEmail}
                label="Lender Email Address"
                id="lender-email"
                onBlur={(e) => trackInputValue(e, register)}
                InputProps={{
                  classes: {
                    input: 'csaa-qm-encrypt',
                  },
                }}
                fullWidth
                name="lenderEmail"
                helperText={`${
                  errors.lenderEmail?.message || 'Your email address'
                }`}
                onChange={handleInput}
                required
              />
            </Stack>
            {isRecaptchaEnabled && (
              <Box sx={{ mt: 2 }}>
                <ReCAPTCHA
                  sitekey={
                    process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ''
                  }
                  onChange={(token) => {
                    setRecaptchaToken(token || '');
                    setValue('recaptchaToken', token || '');
                    trigger('recaptchaToken');
                  }}
                  size="normal"
                />
                <Typography variant="body2" className="recaptcha-error">{`${
                  errors.recaptchaToken?.message || recaptchaMessage
                }`}</Typography>
              </Box>
            )}
            <Stack flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleSubmit(() => {
                  sessionStorage.setItem(
                    'PolicyLookup',
                    JSON.stringify({
                      ...insuredContext,
                      incorrectPolicyInformation: false,
                      errorRetrievingPolicy: false,
                    })
                  );
                  if (isRecaptchaEnabled) {
                    makeRequest(() =>
                      validateRecaptcha({ token: recaptchaToken })
                        .then((response: any) => {
                          if (!response?.success) {
                            return Promise.reject();
                          }
                          navigate('/otvc');
                        })
                        .catch(() => {
                          setRecaptchaMessage(
                            'There was a problem validating the reCaptcha response.'
                          );
                        })
                    );
                  } else {
                    navigate('/otvc');
                  }
                  identifySegmentUser(insuredContext);
                  trackSegmentUser(insuredContext);
                })}
                disabled={
                  !policyLookupSchema.isValidSync({
                    ...insuredContext,
                    recaptchaToken,
                  })
                }
                sx={{ mt: 5, width: 'auto' }}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default PolicyLookup;
