import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useActionCreators } from '../api/actions';
import useRequest from '../api/makeRequest';
import { LoadingContext } from './contexts/LoadingContext';
import { useNavigate } from 'react-router-dom';
import { InsuredContext } from './contexts/InsuredContext';
import { DocumentsRequest } from '../api/schema/apiDocuments';
import useSegment from '../hooks/useSegment';
import { getCookie } from './helpers/cookies';
import { Divider, Stack, Typography } from '@mui/material';

function ReviewRequest() {
  const [contactInfo, setContactInfo] = useState(
    {} as {
      name: string;
      lenderName: string;
      phone: string;
      email: string;
      fax: string;
    }
  );
  const [documentDetails, setDocumentDetails] = useState(
    {} as DocumentsRequest
  );
  const [requestDocumentsSuccess, setRequestDocumentsSuccess] = useState(false);
  const [insuredContext] = useContext(InsuredContext);
  const [, updateLoadingContext] = useContext(LoadingContext);
  const isRealTimeEnabled = process.env.REACT_APP_ENABLE_MORTGAGEE === 'true';
  const navigate = useNavigate();
  const navigateHome = useNavigate();
  useEffect(() => {
    if (!getCookie('token')) {
      navigateHome('/');
    }
    setRequestDocumentsSuccess(false);
    setContactInfo(JSON.parse(sessionStorage.getItem('ContactInfo') || '{}'));
    setDocumentDetails(JSON.parse(sessionStorage.getItem('Documents') || '{}'));
  }, []);

  const ShowEmail = () => {
    return (
      <div>
        <div>
          <Typography
            sx={{
              color: '#0B1421',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            Email address
          </Typography>
        </div>
        <Typography
          sx={{
            color: '#4D5160',
            lineHeight: '24px',
          }}
        >
          {' '}
          {contactInfo.email}
        </Typography>
      </div>
    );
  };
  const ShowFax = () => {
    return (
      <div>
        <div>
          <Typography
            sx={{
              color: '#0B1421',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            Fax number
          </Typography>
        </div>
        <Typography
          sx={{
            color: '#4D5160',
            lineHeight: '24px',
          }}
        >
          {contactInfo.fax}
        </Typography>
      </div>
    );
  };

  const makeRequest = useRequest();
  const { track } = useSegment();

  const buildRequest = () => {
    const requestBody = {
      ...documentDetails,
      ...{
        requestorName: contactInfo.name,
        phoneNumber: contactInfo.phone,
        lenderName: contactInfo.lenderName,
      },
    };
    if (contactInfo.email) {
      requestBody.documentRequest.deliveryMethod.email = contactInfo.email;
    }
    if (contactInfo.fax) {
      requestBody.documentRequest.deliveryMethod.phone = contactInfo.fax;
    }
    return requestBody;
  };

  const {
    actionCreators: { requestDocuments, updateLiens },
  } = useActionCreators();

  const storeContactInfoAndNavigate = (isUpdateMortgagee = false) => {
    const currentContactInfo = JSON.parse(
      sessionStorage.getItem('ContactInfo') || '{}'
    );
    sessionStorage.setItem(
      'ContactInfo',
      JSON.stringify({
        ...currentContactInfo,
        isUpdateMortgagee,
      })
    );
    updateLoadingContext({ value: false });
    navigate('/success');
  };

  const storeRealTimeContactInfoAndNavigate = () => {
    const currentContactInfo = JSON.parse(
      sessionStorage.getItem('ContactInfo') || '{}'
    );
    sessionStorage.setItem(
      'ContactInfo',
      JSON.stringify({
        ...currentContactInfo,
      })
    );
    updateLoadingContext({ value: false });
    navigate('/details');
    sessionStorage.setItem(
      'Request Documents Success',
      JSON.stringify(requestDocumentsSuccess === true)
    );
  };

  const requestDocs = (request: DocumentsRequest) => {
    makeRequest(() =>
      requestDocuments(insuredContext.policyNumber || '', request)
        .then(() => {
          if (isRealTimeEnabled) {
            storeRealTimeContactInfoAndNavigate();
          } else {
            storeContactInfoAndNavigate();
          }
        })
        .catch(() => {
          if (isRealTimeEnabled) {
            storeRealTimeContactInfoAndNavigate();
          } else {
            storeContactInfoAndNavigate();
          }
        })
    );
  };

  const updateMortgagee = (request: DocumentsRequest) => {
    makeRequest(() =>
      updateLiens(insuredContext.policyNumber || '', request)
        .then((response: any) => {
          storeContactInfoAndNavigate(true);
          track('Mortgagee Change Succeeded', {
            event_type: 'Automated System Process',
            document: request?.documentRequest?.documentTypes?.join(),
            ticket_number: response?.ticketId,
            delivery_method: Object.keys(
              request?.documentRequest?.deliveryMethod
            ).join(),
          });
          track('Exception Ticket Created', {
            ticket_source: 'Zendesk',
            ticket_number: response?.ticketId,
            event_type: 'Automated System Process',
          });
        })
        .catch(() => {
          storeContactInfoAndNavigate(true);
        })
    );
  };

  const handleClick = () => {
    const request = buildRequest();
    updateLoadingContext({
      value: true,
      message: 'We’re gathering details of the policy you’ve requested.',
    });
    track('Document Request Submitted', {
      event_type: 'User Information Entered',
      agent_name: request.requestorName,
      lender_name: request.lenderName,
      phone_number: request.phoneNumber,
      fax_number: request?.documentRequest?.deliveryMethod?.phone,
      email: request?.documentRequest?.deliveryMethod?.email,
    });
    if (request?.mortgageeChanges) {
      updateMortgagee(request);
    } else {
      requestDocs(request);
    }
  };

  const handleRealTimeClick = () => {
    const request = buildRequest();
    updateLoadingContext({
      value: true,
      message: 'We’re gathering details of the policy you’ve requested.',
    });
    track('Document Request Submitted', {
      event_type: 'User Information Entered',
      agent_name: request.requestorName,
      lender_name: request.lenderName,
      phone_number: request.phoneNumber,
      fax_number: request?.documentRequest?.deliveryMethod?.phone,
      email: request?.documentRequest?.deliveryMethod?.email,
    });
    requestDocs(request);
  };

  return (
    <div className="DocumentDetailsCard">
      <div className="PolicyDetailsCard-title">Review your information</div>
      <div className="PolicyDetailsCard-main">
        <Container disableGutters sx={{ p: 5, bgcolor: '#ffffff' }}>
          <Box className="PolicyDetailsCard-box-details">
            <div className="PolicyDetailsCard-details-section">
              <div className="flexForm">
                <Typography
                  variant="h1"
                  sx={{ fontSize: 28, fontWeight: 700 }}
                  className="title-middle"
                >
                  Contact information
                </Typography>
                <Stack
                  sx={{
                    px: 3,
                    py: 1,
                    border: 'solid 1px #E3E5EE',
                    borderRadius: 2,
                  }}
                  divider={<Divider sx={{ my: 1 }} />}
                >
                  <div>
                    <div>
                      <Typography
                        sx={{
                          color: '#0B1421',
                          fontWeight: 700,
                          lineHeight: '24px',
                        }}
                      >
                        Name
                      </Typography>
                    </div>
                    <Typography
                      sx={{
                        color: '#4D5160',
                        lineHeight: '24px',
                      }}
                    >
                      {contactInfo.name}
                    </Typography>
                  </div>

                  <div>
                    <div>
                      <Typography
                        sx={{
                          color: '#0B1421',
                          fontWeight: 700,
                          lineHeight: '24px',
                        }}
                      >
                        Lender name
                      </Typography>
                    </div>
                    <Typography
                      sx={{
                        color: '#4D5160',
                        lineHeight: '24px',
                      }}
                    >
                      {contactInfo.lenderName}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        color: '#0B1421',
                        fontWeight: 700,
                        lineHeight: '24px',
                      }}
                    >
                      <b>Phone number</b>
                    </Typography>
                    <Typography
                      sx={{
                        color: '#4D5160',
                        lineHeight: '24px',
                      }}
                    >
                      {contactInfo.phone}
                    </Typography>
                  </div>
                  {contactInfo.email && <ShowEmail />}
                  {contactInfo.fax && <ShowFax />}
                </Stack>
              </div>
            </div>
            <Typography sx={{ fontSize: { xs: 14 }, mx: 'auto', mt: 3 }}>
              Select finish below to process your request. Your documents will
              be delivered to the methods selected shortly.
            </Typography>
            <Stack
              flexDirection="row"
              justifyContent="flex-end"
              useFlexGap
              spacing={1}
              sx={{ mt: 5 }}
            >
              {!isRealTimeEnabled ? (
                <>
                  <Button variant="contained" onClick={handleClick}>
                    Submit request
                  </Button>
                  <Button variant="outlined" href="/documents">
                    Edit Information
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" onClick={handleRealTimeClick}>
                    Submit request
                  </Button>
                  <Button variant="outlined" href="/realTimeDocuments">
                    Edit Information
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default ReviewRequest;
