import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import useSegment from '../hooks/useSegment';
import SessionInactiveModal from './modal/SessionInactiveModal';

const IdleTimer = () => {
  const [inactiveModalDisplayed, setInactiveModalDisplayed] = useState(false);
  const navigate = useNavigate();
  const { track } = useSegment();
  const onClose = () => {
    track('Session Restarted', { event_type: 'Automated System Process' });
    setInactiveModalDisplayed(false);
    navigate('/');
  };
  const onIdle = () => {
    track('Session Expired', { event_type: 'Automated System Process' });
    setInactiveModalDisplayed(true);
  };
  const IDLE_TIMER_LENGTH = 1000 * 60 * 4; //10 sec
  const EXCLUDED_FROM_IDLE_TIMER_PAGES = ['/'];
  const { pathname } = useLocation();
  const pageUsesTimer = !EXCLUDED_FROM_IDLE_TIMER_PAGES.includes(pathname);

  const handlePageChange = () => {
    if (pageUsesTimer) {
      start();
    } else {
      pause();
    }
  };

  useEffect(() => {
    handlePageChange();
  }, [pathname]);

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout: IDLE_TIMER_LENGTH,
    crossTab: true,
    startManually: true,
    stopOnIdle: true,
    element: document,
  });

  return (
    <>
      <SessionInactiveModal
        open={inactiveModalDisplayed}
        onAffirmative={onClose}
        onClose={onClose}
      />
    </>
  );
};

export default IdleTimer;
