import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getRoutes } from './components/helpers/Pages';
import TagManager from 'react-gtm-module';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    analytics?: any;
    recaptchaOptions?: any;
  }
}

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM || '',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={createBrowserRouter(getRoutes())} />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
