import * as yup from 'yup';

const mortChangeModalSchema = yup.object({
  legalMortgageeName: yup
    .string()
    .required('Required field')
    .matches(/^[a-zA-Z0-9\s-()&.',?]+$/, 'Mortgagee Name must be alphanumeric'),
  mortgageeAddress1: yup
    .string()
    .required('Enter address')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Mortgagee Address must be alphanumeric'),
  mortgageeCity: yup
    .string()
    .required('Enter City')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Mortgagee City must be alphanumeric'),
  mortgageeState: yup
    .string()
    .required('Select a state')
    .matches(
      /^[A-Z]{2}$/,
      'Mortgagee State must contains only two uppercase letters'
    ),
  mortgageePostalCode: yup
    .string()
    .required('Enter a valid zip code')
    .matches(
      /^\d{5}$|^\d{9}$/,
      'Mortgagee Postal Code must have only 5 or 9 numbers'
    ),
  loanNumber: yup
    .string()
    .required('Required field')
    .matches(/^[a-zA-Z0-9]+$/, 'Loan Number must be alphanumeric'),
});

export default mortChangeModalSchema;
