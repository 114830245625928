import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { InsuredContext } from '../components/contexts/InsuredContext';

const useSegment = () => {
  const [insuredContext] = useContext(InsuredContext);
  const { pathname } = useLocation();

  const globalProperties = useMemo(
    () => ({
      category: pathname,
      label: undefined,
      email: insuredContext?.lenderEmail,
      policy_number: insuredContext?.policyNumber,
      event_type: undefined,
      lender_name:
        insuredContext.lenderName === 'Other - Please Specify'
          ? insuredContext.lenderOther
          : insuredContext.lenderName,
    }),
    [insuredContext, pathname]
  );

  const track = useCallback(
    (eventName: string, properties: Record<string, unknown>) => {
      const trackFn = window.analytics?.track || console.warn;

      if (properties.eventType) {
        properties.event_type = properties.eventType;
        delete properties.eventType;
      }
      return trackFn(eventName, {
        ...globalProperties,
        label: eventName,
        ...properties,
      });
    },
    [globalProperties]
  );

  const identify = useCallback((id: string | undefined, properties: any) => {
    window.analytics.identify(id, properties);
  }, []);

  const page = useCallback(
    (pageName: string, properties?: Record<string, unknown>) => {
      const page = window.analytics?.page || console.warn;
      return page(pageName, {
        ...properties,
      });
    },
    [globalProperties]
  );

  return {
    identify,
    track,
    page,
  };
};

export default useSegment;
